body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

.loading-dots:after {
  content: ' .';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgba(0, 0, 0, 0.54);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0.54), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0.54), 0.5em 0 0 rgba(0, 0, 0, 0.54);
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgba(0, 0, 0, 0.54);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0.54), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0.54), 0.5em 0 0 rgba(0, 0, 0, 0.54);
  }
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: #fffb00;
}

.react-datepicker-wrapper {
  width: 160px;
  display: inline-block;
}

input.react-datepicker-time__input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 4px;
  border-radius: 8px;
}

input.react-datepicker-time__input:focus {
  border: 1px solid #4caf50;
  outline: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.lciMme {
  border: none !important;
}

.iYLKOj {
  border: none !important;
}

.DateTimePicker__FlexItem-sc-1kybx8t-1 {
  background-color: #fff;
  border: none;
  outline: 0;
  width: 100px;
}

.DateTimePicker__FlexItem-sc-1kybx8t-1:focus {
  border: none;
  outline: 0;
}

